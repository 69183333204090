















































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'

// components
import CommissionQuotaAttainmentGroups from '@/components/dashboard/CommissionQuotaAttainmentGroups.vue'
import PivotBarChart from '@/components/charts/PivotBarChart.vue'

// utils
import {
  AmountField,
  amountFields,
  Query,
} from '@/components/charts/chart-settings'

// types
import { ID } from '@/types'

export default Vue.extend({
  metaInfo: {
    title: 'Customers Charts - Dashboard',
  },
  components: {
    CommissionQuotaAttainmentGroups,
    PivotBarChart,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    amountFieldId: 'postedAmount',
    amountFields: amountFields,

    productCategoryIds: [] as ID[],
    excludeProductCategoryIds: [] as ID[],

    dialogData: undefined as
      | undefined
      | {
          amountFieldId: string
          productCategoryIds: number[]
          excludeProductCategoryIds: number[]
        },
  }),
  computed: {
    ...mapGetters(['productCategories']),
    amountField(): AmountField {
      return (
        this.amountFields.find(z => z.id === this.amountFieldId) ||
        amountFields[0]
      )
    },
    where(): Query {
      const query: Query = {}

      if (this.productCategoryIds && this.productCategoryIds.length > 0)
        query.productCategoryId = this.productCategoryIds.join('|')

      if (
        this.excludeProductCategoryIds &&
        this.excludeProductCategoryIds.length > 0
      )
        query['productCategoryId!'] = this.excludeProductCategoryIds.join('|')

      return query
    },
  },
  methods: {
    rowFinder(id: ID): string {
      return this.$store.getters.getCustomerById(id).abbr
    },
    columnFinder(id: ID): string {
      return this.$store.getters.getProductCategoryById(id).abbr
    },
    openDialog(): void {
      this.dialogData = cloneDeep({
        amountFieldId: this.amountFieldId,
        productCategoryIds: this.productCategoryIds,
        excludeProductCategoryIds: this.excludeProductCategoryIds,
      })
    },
    closeDialog(): void {
      this.dialogData = undefined
    },
    applyDialog() {
      if (!this.dialogData) throw new Error('no dialog data')

      this.amountFieldId = this.dialogData.amountFieldId
      this.productCategoryIds = this.dialogData.productCategoryIds
      this.excludeProductCategoryIds = this.dialogData.excludeProductCategoryIds

      this.closeDialog()
    },
  },
})
